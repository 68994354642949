body {
  background: url(../images/charte/bg-home.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}




.parallax-window {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, .6) 100%);
  
  >a {
    display: inline-block;
    position: relative;
    font-size: 2rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    color: white;

    >img {
      @extend .img-responsive;
      max-width: 90%;
      margin: 0 auto;
      transition: transform ease-out .3s;
    }

    >span {
      display: inline-block;
      transition: letter-spacing ease-out .3s;
    }

    @media(min-width: $screen-sm-min) {
      font-size: 1rem;
    
      &:hover {
        opacity: .5;
  
        >img {
          transform: scale(1.1);
        }

    
      }
    }

    @media(min-width: $screen-md-min) {
      font-size: 4rem;
    }
  }
}


//contact button

.contact-btn {
  position: fixed;
  top: 20rem;
  right: 0;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 1000;
  padding: 20px 10px;
  background-color: $brand-second;
  border-radius: 0 0.5rem 0.5rem  0;
  color: rgb(223, 220, 220);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  color: $brand-primary;
  &:hover {
    color: black;
  }
}

.contact-btn2 {
  position: fixed;
  top: 40rem;
  right: 0;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 1000;
  padding: 20px 10px;
  background-color: $brand-second;
  border-radius: 0 0.5rem 0.5rem  0;
  color: rgb(223, 220, 220);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  color: $brand-primary;
  &:hover {
    color: black;
  }
}


.border-pink {
  border-style: solid;
  border-width: 6px;
  border-image:  linear-gradient(179.4deg, rgb(253, 240, 233) 2.2%, rgb(255, 194, 203) 96.2%);
  border-image-slice: 1.5;

}


.figure__compo-1 {
  position: relative;
  padding: 1rem;
  height: 40rem;
  max-height: 100%;
  width: 40rem;
  max-width: 100%;
}

.figure__compo-1 img {
  position: absolute;
  object-fit: cover;
}

.figure__compo-1 img:nth-child(1) {
  top: 0;
  left: 0;
  width: 70%;
  height: 55%;
}

.figure__compo-1 img:nth-child(2) {
  bottom: 0;
  right: 0;
  width: 70%;
  height: 55%;
}